/* How to change color of dots on active class
 * https://github.com/akiran/react-slick/issues/2046
 */

.dots {
  :global(.slick-active) button:before {
    color: #58c1d7 !important;
  }

  button:hover:before,
  button:focus:before {
    color: #58c1d7 !important;
  }

  li {
    margin: 0 1px 0 !important;
  }
}
