.highway-slider {
  display: flex;
  width: 100%;
  height: 200px;
}

.highway-barrier {
  --background-color: #fff;
  overflow: hidden;
  position: relative;
  background: var(--background-color);
  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 9;
    width: 10%;
    height: 100%;
  }
  &::before {
    top: 0;
    left: 0;
    background: linear-gradient(
      to right,
      var(--background-color) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  &::after {
    top: 0;
    right: 0;
    background: linear-gradient(
      to left,
      var(--background-color) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.highway-lane {
  width: calc(180px * 24);
  display: flex;
  height: 100%;
}

.highway-car {
  width: 100%;
  animation: translateinfinite 8s linear infinite;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@keyframes translateinfinite {
  100% {
    transform: translateX(calc(-180px * 12));
  }
}
