.popper {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  &[data-popper-placement^="top"] .arrow {
    bottom: -4px;
  }

  &[data-popper-placement^="bottom"] > .arrow {
    top: -4px;
  }

  &[data-popper-placement^="left"] > .arrow {
    right: -4px;
  }

  &[data-popper-placement^="right"] > .arrow {
    left: -4px;
  }
}

.arrow,
.arrow::before {
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  background: white;
  z-index: -1;
  @apply shadow-sm;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  content: "";
  visibility: visible;
  transform: rotate(45deg);
}
