@mixin bullet($color) {
  content: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10Z' fill='%23" + $color + "'/%3E%3C/svg%3E%0A");
  @apply absolute left-0 top-0 ml-1 mr-2;
}

@mixin star-bullet {
  content: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.5 0L10.5484 5.68058L16.584 5.87336L11.8144 9.57692L13.4962 15.3766L8.5 11.985L3.50383 15.3766L5.18557 9.57692L0.416019 5.87336L6.45157 5.68058L8.5 0Z' fill='%23F4C854'/%3E%3C/svg%3E%0A");
  @apply ml-0 mr-[5px];
}

.markdown {
  > ul {
    li {
      @apply relative pl-6 text-zinc-600;
      b {
        @apply text-black;
      }
    }

    mark {
      @apply bg-yellow-300;
    }

    > li:not(:has(ul)) {
      &::before {
        @include bullet("416579");
      }

      &[class~="star"]::before {
        @include star-bullet;
      }

      @apply my-4 after:absolute after:-top-2 after:left-0 after:w-full after:border-t-[1px] after:border-zinc-200;

      &:first-child {
        @apply after:hidden;
      }
    }

    > li:has(ul) {
      @apply -mt-4;
    }

    ul li:not(:has(ul)) {
      &::before {
        @include bullet("BDBDBD");
      }
    }
  }
}
