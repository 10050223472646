.custom-radio {
  --checked-color: #2d80af;
  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + label {
    font-weight: 400;
    font-size: 14px;
    @apply cursor-pointer;
  }

  input[type="radio"] + label span {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: -4px 7px 0 0;
    cursor: pointer;
    vertical-align: middle;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 3px solid #ffffff;
    background-color: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }

  input[type="radio"]:checked + label {
    color: #333;
    font-weight: 700;
  }

  input[type="radio"]:checked + label span {
    background-color: var(--checked-color);
    border: 3px solid #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }

  input[type="radio"] + label span,
  input[type="radio"]:checked + label span {
    -webkit-transition: background-color 0.24s linear;
    -o-transition: background-color 0.24s linear;
    -moz-transition: background-color 0.24s linear;
    transition: background-color 0.24s linear;
  }
}
