.markdown {
  @apply space-y-2 text-sm;

  h1 {
    @apply text-lg font-bold @lg:text-xl;
  }

  h2 {
    @apply text-lg font-bold @lg:text-xl;
  }

  h3 {
    @apply text-base font-bold @lg:text-lg;
  }

  h4 {
    @apply text-sm font-bold @lg:text-base;
  }

  h5 {
    @apply text-sm font-bold @lg:text-base;
  }

  p {
    @apply text-sm;
  }

  pre:has(code) {
    @apply whitespace-pre-wrap rounded-xl bg-zinc-300 p-2;
  }

  ul {
    list-style: disc outside;
    padding-left: 1.5rem;
  }

  ol {
    list-style: decimal outside;
    padding-left: 1.5rem;
  }

  a {
    @apply font-medium text-blue-500 underline;
  }

  table {
    @apply border-collapse rounded-xl border border-zinc-400 text-sm font-semibold;

    th {
      @apply border border-zinc-400 bg-zinc-400 px-2 py-1 text-white;
    }

    td {
      @apply border border-zinc-400 px-2 py-1;
    }
  }
}
